<template>
  <b-container fluid>
    <b-row class="heading_alignment">
      <b-col md="12">
        <iq-card style="padding-left: 20px; padding-right: 20px; width: 100%">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ cvCardTitle }}
            </h4>
            <span>On your <span class="primary-color">(DNS)</span> Domain Name Service Provider <span class="primary-color">(Eg. GoDaddy, WIX, BigRock, from where you purchaed your domain)</span>, you will have to add a <span class="primary-color">CNAME</span> record. This record will help us navigate the users to your landing page on GIDE.AI</span><br /><br />

            <h5>Typical configuration steps to be executed on your domain name service (DNS) provider:</h5>
            <ul style="list-style: disc;">
              <li>In the <span class="primary-color">CNAME (Aliases)</span> section, click the <span class="primary-color">+Add Record</span></li>
              <li>For <span class="primary-color">Host Name</span> copy/paste the Host Name info from the CNAME below</li>
              <li>For <span class="primary-color">Value</span> copy/paste the Points To info from the CNAME below</li>
              <li>For <span class="primary-color">TTL</span> choose <span class="primary-color">30 minuites</span></li>
              <li>Click <span class="primary-color">Save</span></li>
              <li>Click <span class="primary-color">Save Changes</span> in the pop-up</li>
            </ul>
            <br />
            <div class="domain_details_container mb-4">
              <div style="border-bottom: 1px solid #000;" class="org_domain mb-2">
                <span style="width:inherit">CNAME</span>
              </div>
              <!-- Host Name -->
              <div>
                <h5>
                  Host Name
                </h5>
                <b-form>
                  <div class="col-md-12 mb-3 mt-3 p-0">
                    <input v-model="vmOrgDomainValidateFormData.org_domain" type="text" class="domain_fields">
                    <span class="ml-2 primary-color pointer" style="font-weight: bold;" title="copy to clipboard" @click="doCopy(vmOrgDomainValidateFormData.org_domain)">Copy</span>
                  </div>
                </b-form>
              </div><!-- Host Name -->

              <!-- Points To -->
              <div>
                <h5>
                  Points To
                </h5>
                <b-form>
                  <div class="col-md-12 mb-3 mt-3 p-0">
                    <input v-model="cnameDomain" type="text" class="domain_fields">
                    <span class="ml-2 primary-color pointer" style="font-weight: bold;" title="copy to clipboard" @click="doCopy(cnameDomain)">Copy</span>
                  </div>
                </b-form>
              </div><!-- Points To -->
            </div>
          </template>
          <template>
            <b-row>
              <b-toast no-auto-hide v-model="showStaticToast" name="Toast" :variant="toastVariant" :title="toastTitle">
                <div v-html="toastMsg">
                </div>
              </b-toast>
              <b-col class="container_minheight" md="12">
                <b-button variant="primary" class="pull-right pointer mb-4" @click="startDomainConfiguration">Start Validation</b-button>
                <b-table
                  hover
                  responsive
                  :items="items"
                  :fields="columns"
                >
                  <template v-slot:cell(steps)="data">
                    <div style="display: flex; justify-content: space-between; align-items: center">
                      <p v-if = "data.item.state != 'COMPLETED'">{{data.item.steps}}</p>
                      <p v-else v-html="data.item.steps"></p>
                    </div>
                  </template>
                  <template v-slot:cell(validation_status)="data">
                    <div style="display: flex; justify-content: space-between; align-items: center">
                      <!-- Loader -->
                      <div v-if="setLoaderVisibility(data.item)" class="text-center mt-3">
                        <b-spinner variant="primary" label="Spinning"></b-spinner>
                      </div><!-- Loader -->

                      <!-- Success -->
                      <div v-if="orgData && (orgData.org_domain_is_validated || orgData.org_domain_is_validated == 0) && setSuccessIconVisibility(data.item)">
                        <i class="fa-solid fa-check fa-lg" style="color: green;" ria-hidden="true"></i>
                      </div><!-- Success -->

                      <!-- Failure -->
                      <div v-if="orgData && (orgData.org_domain_is_validated || orgData.org_domain_is_validated == 0) && setFailureIconVisibility(data.item)">
                        <i class="fa-solid fa-xmark fa-lg" style="color: red;" ria-hidden="true"></i>
                      </div><!-- Failure -->
                    </div>
                  </template>
                  <template v-slot:cell(toastMsg)="data">
                    <div style="display: flex; justify-content: space-between; align-items: center">
                      <div v-if="orgData && (orgData.org_domain_is_validated || orgData.org_domain_is_validated == 0) && setFailureIconVisibility(data.item)">
                        {{ toastMsg }}
                      </div><!-- Failure -->
                    </div>
                  </template>
                </b-table>
                <span class="pull-left mb-4 ml-2">
                  <span class="warning-color">It take couple of minutes for the configuration on your DNS Provider to propogate on internet. During the validation process if you see an error message then just wait for a couple of minutes to</span>&nbsp;
                  <b-button variant="primary" class="pointer" @click="startDomainConfiguration">Restart Validation</b-button>
                </span>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { socialvue } from "../../../config/pluginInit"
import { Organisations } from "../../../FackApi/api/organisation"
import ApiResponse from "../../../Utils/apiResponse"

export default {
  name: "OrgSaasDomainValidate",
  async mounted () {
    socialvue.index()
    this.orgType = this.$route.params.org_type
    this.cvOrgId = this.$route.params.org_id

    await this.getOrgDetails()
  },
  data () {
    return {
      cvCardTitle: "Validate Your Domain",
      cvOrgDomain: "organisation domain",
      vmOrgDomainValidateFormData: Object.assign({}, this.initFormData()),
      cvSubmitBtn: "Validate",
      showToast: false,
      showStaticToast: false,
      toastVariant: "default",
      toastTitle: "Website Management",
      toastMsg: "",
      orgType: null,
      cvOrgId: null,
      orgData: null,
      /*
       0 => process started,
       1 => process completed,
       2 => process failed,
       99 => process yet to start
      */
      domainValidationStage: {
        HTTP_VALIDATION: 99,
        SSL_CERT_GEN: 99,
        HTTPS_VALIDATION: 99,
        COMPLETED: 99
      },
      propOpenedInModal: false,
      cnameDomain: process.env.VUE_APP_CNAME_DOMAIN,
      items: [
        { steps: "Step 1: Validating Your Domain", state: "HTTP_VALIDATION" },
        { steps: "Step 2: Generating Security (SSL) Certificates for Your Domain", state: "SSL_CERT_GEN" },
        { steps: "Step 3: Verifying Your Domain's Security Certificate (HTTPs/SSL)", state: "HTTPS_VALIDATION" }
      ],
      columns: [
        { label: "3 Step Validation", key: "steps", class: "text-left align-middle w-300px" },
        { label: "Status", key: "validation_status", class: "text-left align-middle" },
        { label: "Info Message", key: "toastMsg", class: "text-left align-middle" }
      ]
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    /**
     * getOrgDetails
     */
    async getOrgDetails () {
      try {
        const orgViewResp = await Organisations.organisationView(this, this.cvOrgId)
        if (!orgViewResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, orgViewResp)
          return
        }

        this.orgData = orgViewResp.resp_data.data
        if (this.orgData.org_domain) {
          this.vmOrgDomainValidateFormData.org_domain = this.orgData.org_domain
        }

        if (this.orgData.org_domain_is_validated == 99) {
          // DOMAIN_VALIDATION_COMPLETE
          this.toastMsg = "Your domain has already been verified & validated. Redirecting you back to the organisation dashboard"
          this.toastVariant = "danger"
          this.showToast = true
          setTimeout(() => {
            this.$router.push(`/org_edit/${this.orgData.type}/${this.orgData.org_id}`)
          }, 3000)
        }
      }
      catch (err) {
        console.error("Exception in getOrgDetails and err: ", err)
      }
    },
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")
      document.body.appendChild(dummy)
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    },
    /**
     * initFormData
     */
    initFormData () {
      return {
        org_domain: ""
      }
    },
    /**
     * startDomainConfiguration
     */
    async startDomainConfiguration () {
      try {
        if (!this.vmOrgDomainValidateFormData.org_domain) {
          this.toastMsg = "Fill in the mandatory fields"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }

        if (this.orgData.org_domain_is_validated == 99) {
          // domain validation complete
          this.toastMsg = "Your domain has already been verified & validated. Redirecting you back to the organisation dashboard"
          this.toastVariant = "danger"
          this.showToast = true
          setTimeout(() => {
            this.$router.push(`/org_edit/${this.orgData.type}/${this.orgData.org_id}`)
          }, 3000)
          return
        }

        if (this.orgData.org_domain_is_validated == 0 || this.domainValidationStage.HTTP_VALIDATION == 0) {
          // domain validation on http protocol started
          this.domainValidationStage.HTTP_VALIDATION = 0
          const validateDomainOnProtocolResp = await this.validateDomainOnPort(this.vmOrgDomainValidateFormData.org_domain, 80)
          if (!validateDomainOnProtocolResp.resp_status) {
            this.domainValidationStage.HTTP_VALIDATION = 2
            // ApiResponse.responseMessageDisplay(this, validateDomainOnHttpsResp)
            this.displayErrMsg("Your domain is not correctly pointed to the GIDE.AI CNAME. Refer to the DNS Settings above")
            return
          }
          // domain validation on http protocol completed
          this.domainValidationStage.HTTP_VALIDATION = 1
        }

        if (this.orgData.org_domain_is_validated == 1 || this.domainValidationStage.HTTP_VALIDATION == 1) {
          // ssl cert gen
          const sslCertGenResp = await this.generateSSLCertificate()
          if (!sslCertGenResp.resp_status) {
            this.domainValidationStage.SSL_CERT_GEN = 2
            // ApiResponse.responseMessageDisplay(this, validateDomainOnHttpsResp)
            this.displayErrMsg("Security certificate generation or validation for your domain failed. After CNAME mapping it may take some time for the domain settings to reflect.Pls re-try again after 5 mins. If the error persists, please contact support@gide.ai")
            return
          }
          /* Wait for 2 secs before validating the domain on HTTPs protocol because the certificate talkes a slight amount of time before
            it starts getting served with the request and if we make an early request to the domain the axios package at the backend may
            throw an exception */

          await new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve()
            }, 2000)
          })

          // ssl certificate generation process completed
          this.domainValidationStage.SSL_CERT_GEN = 1
        }

        if (this.orgData.org_domain_is_validated == 2 || this.domainValidationStage.SSL_CERT_GEN == 1) {
          // domain validation on https protocol started
          this.domainValidationStage.HTTPS_VALIDATION = 0
          let retries = 3
          let validateDomainOnHttpsResp = null
          while (retries > 0) {
            validateDomainOnHttpsResp = await this.validateDomainOnPort(this.vmOrgDomainValidateFormData.org_domain, 443)
            if (validateDomainOnHttpsResp.resp_status) {
              break
            }

            // wait for 2 secs between each retry
            await new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve()
              }, 2000)
            })

            retries--
          }

          if (retries == 0 && !validateDomainOnHttpsResp.resp_status) {
            this.domainValidationStage.HTTPS_VALIDATION = 2
            // ApiResponse.responseMessageDisplay(this, validateDomainOnHttpsResp)
            this.displayErrMsg("Validation of domain on HTTPs protocol failed")
            return
          }
          else if (validateDomainOnHttpsResp.resp_status) {
            // domain validation on http protocol completed
            this.domainValidationStage.HTTPS_VALIDATION = 1
            this.domainValidationStage.COMPLETED = 1
            this.items.push({ steps: `<b style="color: #109618; font-weight: bold;">Domain verification succssful. Domain is now secured and active. Please visit your website: </b><a href="https://${this.vmOrgDomainValidateFormData.org_domain}" target="_blank">${this.vmOrgDomainValidateFormData.org_domain}</a>`, state: "COMPLETED" })
          }
        }
      }
      catch (err) {
        console.error("Exception in startDomainConfiguration() and err: ", err)
      }
    },

    /**
     * generateSSLCertificate
     */
    async generateSSLCertificate () {
      try {
        // ssl certificate generation process started
        this.domainValidationStage.SSL_CERT_GEN = 0
        const certgenObj = {
          domain: this.vmOrgDomainValidateFormData.org_domain,
          ca_authorised: true,
          org_id: this.orgData.org_id,
          user_id: this.userData.user_id
        }
        const sslCertGenResp = await Organisations.organisation_domain_generate_sslcert(this, certgenObj)
        return sslCertGenResp
      }
      catch (err) {
        console.error("Exception in generateSSLCertificate and err: ", err)
      }
    },
    /**
     * validateDomainOnPort
     */
    async validateDomainOnPort (domain, port) {
      try {
        const domainValidateObj = {
          org_id: this.cvOrgId,
          domain: domain,
          port: port
        }
        const validateDomainOnProtocolResp = await Organisations.organisation_domain_validate_on_port(this, domainValidateObj)
        return validateDomainOnProtocolResp
      }
      catch (err) {
        console.error("Exception in validateDomainOnPort and err: ", err)
      }
    },
    /**
     * setLoaderVisibility
     */
    setLoaderVisibility (validationStep) {
      if (validationStep.state == "HTTP_VALIDATION" && this.domainValidationStage["HTTP_VALIDATION"] == 0) {
        return true
      }
      else if (validationStep.state == "SSL_CERT_GEN" && this.domainValidationStage["SSL_CERT_GEN"] == 0) {
        return true
      }
      else if (validationStep.state == "HTTPS_VALIDATION" && this.domainValidationStage["HTTPS_VALIDATION"] == 0) {
        return true
      }
      return false
    },
    /**
     * setSuccessIconVisibility
     */
    setSuccessIconVisibility (validationStep) {
      if (validationStep.state == "HTTP_VALIDATION" && (this.domainValidationStage["HTTP_VALIDATION"] == 1 || this.orgData.org_domain_is_validated >= 1)) {
        return true
      }
      else if (validationStep.state == "SSL_CERT_GEN" && (this.domainValidationStage["SSL_CERT_GEN"] == 1 || this.orgData.org_domain_is_validated >= 2)) {
        return true
      }
      else if (validationStep.state == "HTTPS_VALIDATION" && (this.domainValidationStage["HTTPS_VALIDATION"] == 1 || this.orgData.org_domain_is_validated == 99)) {
        return true
      }
      return false
    },
    /**
     * setFailureIconVisibility
     */
    setFailureIconVisibility (validationStep) {
      if (validationStep.state == "HTTP_VALIDATION" && this.domainValidationStage["HTTP_VALIDATION"] == 2) {
        return true
      }
      else if (validationStep.state == "SSL_CERT_GEN" && this.domainValidationStage["SSL_CERT_GEN"] == 2) {
        return true
      }
      else if (validationStep.state == "HTTPS_VALIDATION" && this.domainValidationStage["HTTPS_VALIDATION"] == 2) {
        return true
      }
      return false
    },
    /**
     * displayErrMsg
     */
    displayErrMsg (msg) {
      this.toastMsg = msg
      this.toastVariant = "danger"
      this.showStaticToast = true
    }
  }
}
</script>

<style>
  .domain_details_container {
    padding: 1.2rem;
    width: max-content;
    background-color: whitesmoke;
  }
  .domain_fields {
    border: 1px solid #000;
    padding: 0.1rem;
  }
  li::marker {
    color: #e5252c !important;
  }
</style>
